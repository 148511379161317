import {Data as InfoCB} from "../generated/openapi/infocb"

export const getUserFullName = (user: InfoCB): string => {
  const representativesName = getRepresentativesName(user)
  const assistentName = getAssistentName(user)
  if (user.isvertreter && representativesName) {
    return representativesName
  }
  if (user.isassistent && assistentName) {
    return assistentName
  }
  return `${user.vorname} ${user.name}`
}

export const getRepresentativesName = (user: InfoCB): string => {
  if (user.isvertreter && user.vertretenvon?.vorname && user.vertretenvon?.name) {
    return user.vertretenvon.vorname + ' ' + user.vertretenvon.name
  } else {
    return ''
  }
}

export const getAssistentName = (user: InfoCB): string => {
  if (user.isassistent && user.vertretenvon?.vorname && user.vertretenvon?.name) {
    return user.vertretenvon.vorname + ' ' + user.vertretenvon.name
  } else {
    return ''
  }
}

export const formatDate = (date: number | string) => {
  let _date
  if (typeof date === 'string') {
    _date = new Date(date)
  } else {
    _date = new Date(date * 1000)
  }
  return new Intl.DateTimeFormat(
    'de',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    .format(_date)
}

export const deviceMapper = (deviceIds: Array<number>): Array<{id: number, labelId: number, icon: string, selected: boolean}> => {
  let devices = [
    {
      id: 1,
      labelId: 105,
      icon: 'assets/img/icons/device_desktop-pc.svg',
      selected: false
    },
    {
      id: 2,
      labelId: 106,
      icon: 'assets/img/icons/device_tablet.svg',
      selected: false
    },
    {
      id: 3,
      labelId: 107,
      icon: 'assets/img/icons/device_handy.svg',
      selected: false
    }
  ]

  return devices.map(device => {
    if (deviceIds!.find(elem => elem === device.id)) {
      device.selected = true
    }

    return device
  })
}
