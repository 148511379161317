@if (isVisible || overrideVisibility) {
  <dx-card [ngClass]="'appitem-type--' + type">
    @if (type === 'big') {
      <dx-card-image fit="proportional"
        coverheightmq3="200px"
        coverpositionmq3="25%"
        url="{{img}}">
      </dx-card-image>
    }
    <dx-card-content class="card-content">
      <div>
        <div class="appitem-metas">
          <dx-icon [icon]="iconName" title="{{title}}" size="72"></dx-icon>
          @if (category) {
            <dx-tag label="{{category}}" type="static" theme="gray"></dx-tag>
          }
        </div>
        <dx-text type="ps" color="gray-83" class="app-item-caption">{{title|stripHtml}}</dx-text>
        <dx-text type="it" class="spacing-8 appitem-desc">{{text|stripHtml}}</dx-text>
      </div>
      <div class="appitem-bottom" slot="footer">
        <div class="appitem-infos">
          @if (showDevices) {
            <div class="appitem-devices">
              @for (device of devices; track device) {
                <img
                  [src]="device.icon"
                  title="{{textObjects[device.labelId]}}"
                  alt="{{textObjects[device.labelId]}}"
                  [ngClass]="{'appitem--selected': device.selected}"
                  />
              }
            </div>
          }
          <div>
            @if (infoURL) {
              <a id="app-learn-more-{{title}}" href="{{infoURL}}" target="_blank" rel="noopener">
                <dx-button label="{{textObjects[89]}}" type="text" icon="link-extern" iconposition="right" gaEvent="click"
                  gaCategory="{{gaCategoryValue.replace(textObjects[45].singular + '', textObjects[89].singular + '')}}"
                gaLabel="{{title}}" (click)="logLearnMoreEvent()"></dx-button>
              </a>
            }
          </div>
        </div>
        <div class="button-container" [ngClass]="{'inVisible' : (!isVisible || !applicationURL)}">
          @if (applicationURL && isVisible) {
            <div class="button-open-application">
              @if (applicationURL) {
                <a [href]="applicationURL | UnsafeUrlPipe" target="_blank" gaEvent="click"
                  [gaCategory]="gaCategoryValue" [gaLabel]="title">
                  <dx-button id="application-link-{{title}}" class="open-app" stretch="true" label="{{textObjects[45]}}"
                    type="primary-m" icon="link-extern" iconposition="right" (click)="logClickAppEvent()">
                  </dx-button>
                </a>
              }
            </div>
            <div class="button-favorite">
              <dx-button id="application-fav-{{title}}" class="add-app-favorite"
                [type]="isFav ? 'primary-m' : 'secondary-m'" [stretch]="type === 'small'" icon="lesezeichen-ausgefuellt"
                iconposition="right" label="{{isFav ? textObjects[90] : textObjects[91]}}" (click)="toggleFav()"
                title="{{isFav ? textObjects[90] : textObjects[91]}}">
              </dx-button>
            </div>
          }
          @if (!isVisible) {
            <dx-icon icon="information" size="24"></dx-icon>
            <dx-text type="it">{{textObjects[114]}}</dx-text>
          }
          @if (isVisible && !applicationURL) {
            <dx-icon icon="information" size="24"></dx-icon>
            <dx-text type="it">{{textObjects[113]}}</dx-text>
          }
        </div>
      </div>
    </dx-card-content>
  </dx-card>
}
