import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {deviceMapper} from "../../utils"
import {AnwendungssucheService} from "../../services/anwendungssuche.service"
import {
  GoogleAnalyticsService,
  NgxGoogleAnalyticsModule
} from 'ngx-google-analytics'
import {Translation} from "../../../generated/openapi/textservice"
import {TextService} from "../../services/text.service"
import { NgClass } from '@angular/common'
import {StripHtmlPipe} from '../../pipes/strip-html.pipe'
import {UnsafeUrlPipe} from '../../pipes/unsafe-url.pipe'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { InsightsService } from '../../services/insights.service'

@Component({
  selector: 'app-appitem',
  standalone: true,
  templateUrl: './appitem.component.html',
  styleUrls: ['./appitem.component.scss'],
  imports: [
    StripHtmlPipe,
    NgClass,
    UnsafeUrlPipe,
    NgxGoogleAnalyticsModule,
    DesignSystemModule
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppitemComponent implements OnInit {

  @Input() img: URL = new URL('https://entwicklung.meine.dvag/vbPortalWebApp/jsp/meineDVAG/vbContent.jsp?https://vbwelt.abnahme.dvag/Api/Files/Get/AB/12166891/images/95fd1fa36b0c472bbd10f4059d5b297f.png?size=s')
  @Input() title: string = ''
  @Input() text: string | undefined
  @Input() category: string | undefined
  @Input() infoURL: string | undefined
  @Input() applicationURL: string | undefined
  @Input() deviceIds: Array<number> = [1,2,3]
  @Input() showDevices: boolean | undefined
  @Input() type: 'small' | 'big' = 'big'
  @Input() isFav: boolean = false
  @Input() isVisible: boolean = false
  @Input() overrideVisibility: boolean = false
  @Input() id: string = ''
  @Input() gaCategoryValue: string = ''
  @Input() gaLocation: string = ''
  @Input() iconName: string = ''
  @Output() showToastEvent: EventEmitter<boolean> = new EventEmitter()
  public textObjects: Array<Translation> = []
  public devices!: ReturnType<typeof deviceMapper>

  constructor(
    private anwendungssucheService: AnwendungssucheService,
    private gaService: GoogleAnalyticsService,
    private insightsService: InsightsService,
    private textService: TextService) {
    this.anwendungssucheService.favChanged$.subscribe(status => {
      if (this.id === status.id) {
        this.isFav = status.status
      }
    })
  }

  ngOnInit() {
    this.devices = deviceMapper(this.deviceIds)
    this.textObjects = this.textService.getTextsObjectsForIds(45,88,89,90,91,113,114,136,137,162,163)
  }

  logLearnMoreEvent() {
    this.insightsService.logEvent('Learn more: ' + this.title)
  }

  logClickAppEvent() {
    this.insightsService.logEvent('Go to App: ' + this.title)
  }

  toggleFav() {
    let category = this.gaCategoryValue

    if (this.isFav) {
      this.anwendungssucheService.removeFavorite(this.id).then((res) => {
        if (res) {
          _toggleFav(137)
        }
      })
    } else {
      this.anwendungssucheService.setFavorite(this.id).then((res) => {
        if (res) {
          _toggleFav(136)
          this.showToastEvent.emit(true)
        }
      })
    }

    const _toggleFav = (textobjectId: number) => {
      this.gaService.event(
        this.textObjects[textobjectId].singular as unknown as string,
        category.replace(
          this.textObjects[45].singular as unknown as string,
          this.textObjects[textobjectId].singular as unknown as string),
        this.title)
    }
    this.isFav = !this.isFav
  }
}
