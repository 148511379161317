import {Inject, Injectable} from '@angular/core'
import {DefaultService as InfoCBService, Data as InfoCB} from '../../generated/openapi/infocb'
import {Router} from '@angular/router'
import {catchError, firstValueFrom, Observable, throwError} from 'rxjs'
import {EnvironmentService} from './environment.service'
import {KeycloakService} from 'keycloak-angular'
import {DOCUMENT} from '@angular/common'
import {shareReplay} from 'rxjs/operators'
import {HttpClient} from '@angular/common/http'

export type ExternalTarget = {
  target: string,
  partner: string,
  ts: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private updateTokenInterval: string | number | NodeJS.Timeout | undefined
  private infoCB$: Observable<InfoCB> = new Observable<InfoCB>()

  constructor(
    public router: Router,
    private http: HttpClient,
    private infoCBService: InfoCBService,
    private envService: EnvironmentService,
    private keycloak: KeycloakService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // none
  }

  async getUser(): Promise<InfoCB> {
    return firstValueFrom(this.infoCB$)
  }

  async getConfig() {
    const config = await this.envService.getConfig()
    this.infoCBService.configuration.basePath = config.apis.infoCB
    this.infoCBService.configuration.credentials = {
      'bearerAuth': await this.keycloak.getToken()
    }

    this.infoCB$ = this.infoCBService.infoCB2JspGet().pipe(shareReplay())
  }

  async initializeKeycloak() {
    // no authentication when health page is called
    if (this.document.location.hash.includes('health')) {
      return
    }

    const config = await this.envService.getConfig()

    try {
      const authenticated = await this.keycloak.init({
        config: {
          url: config.apis.keycloak,
          realm: config.apis.keycloakRealm,
          clientId: config.apis.keycloakClientId
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: true,
          checkLoginIframeInterval: 1000,
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        }
      })

      if (authenticated) {
        this.checkIfRedirected()
        this.getConfig().then()
        this.loadBefoerderungspopup().then()

        // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token
        // Refresh token if it's valid for less than 60 seconds
        this.updateTokenInterval = setInterval(() => this.keycloak?.updateToken(60).catch(() => {
          this.keycloak?.clearToken()
        }), 60000)
      }
    } catch (reason) {
      console.log('Failed to retrieve access token: ' + reason)
    }
  }

  async loadBefoerderungspopup() {
    const config = await this.envService.getConfig()

    // load befoerderungs-popup script in head
    let scriptNode = this.document.createElement('script')
    scriptNode.src = config.apis.befoerderungsPopup
    scriptNode.type = 'text/javascript'
    scriptNode.defer = true
    this.document.getElementsByTagName('head')[0].appendChild(scriptNode)
  }

  checkIfRedirected() {
    const entry = sessionStorage.getItem('externalTarget')
    if (entry) {
      const {target} = <ExternalTarget>JSON.parse(entry)
      sessionStorage.removeItem('externalTarget')

      this.http.get(target, {
        headers: {Authorization: 'Bearer ' + this.keycloak.getKeycloakInstance().token || ''}
      })
        .pipe(catchError((err) => {
          console.log('doExternalCall: ', err)
          return throwError(() => new Error(`doExternalCall: ${err}`))
        }))
        .subscribe((data: Partial<{ url: string }>) => {
          if (data.url) {
            this.router.navigate([]).then(() => {
              this.doRedirect(<string>data.url)
            })
          }
        })
    }
  }

  doRedirect(url: string) {
    this.document.location.href = url
  }
}
