import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Subject, from, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import Keycloak from 'keycloak-js';
import { CommonModule } from '@angular/common';
var KeycloakEventType;
(function (KeycloakEventType) {
  KeycloakEventType[KeycloakEventType["OnAuthError"] = 0] = "OnAuthError";
  KeycloakEventType[KeycloakEventType["OnAuthLogout"] = 1] = "OnAuthLogout";
  KeycloakEventType[KeycloakEventType["OnAuthRefreshError"] = 2] = "OnAuthRefreshError";
  KeycloakEventType[KeycloakEventType["OnAuthRefreshSuccess"] = 3] = "OnAuthRefreshSuccess";
  KeycloakEventType[KeycloakEventType["OnAuthSuccess"] = 4] = "OnAuthSuccess";
  KeycloakEventType[KeycloakEventType["OnReady"] = 5] = "OnReady";
  KeycloakEventType[KeycloakEventType["OnTokenExpired"] = 6] = "OnTokenExpired";
  KeycloakEventType[KeycloakEventType["OnActionUpdate"] = 7] = "OnActionUpdate";
})(KeycloakEventType || (KeycloakEventType = {}));
class KeycloakAuthGuard {
  constructor(router, keycloakAngular) {
    this.router = router;
    this.keycloakAngular = keycloakAngular;
  }
  async canActivate(route, state) {
    try {
      this.authenticated = await this.keycloakAngular.isLoggedIn();
      this.roles = await this.keycloakAngular.getUserRoles(true);
      return await this.isAccessAllowed(route, state);
    } catch (error) {
      throw new Error('An error happened during access validation. Details:' + error);
    }
  }
}
class KeycloakService {
  constructor() {
    this._keycloakEvents$ = new Subject();
  }
  bindsKeycloakEvents() {
    this._instance.onAuthError = errorData => {
      this._keycloakEvents$.next({
        args: errorData,
        type: KeycloakEventType.OnAuthError
      });
    };
    this._instance.onAuthLogout = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthLogout
      });
    };
    this._instance.onAuthRefreshSuccess = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshSuccess
      });
    };
    this._instance.onAuthRefreshError = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshError
      });
    };
    this._instance.onAuthSuccess = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthSuccess
      });
    };
    this._instance.onTokenExpired = () => {
      this._keycloakEvents$.next({
        type: KeycloakEventType.OnTokenExpired
      });
    };
    this._instance.onActionUpdate = state => {
      this._keycloakEvents$.next({
        args: state,
        type: KeycloakEventType.OnActionUpdate
      });
    };
    this._instance.onReady = authenticated => {
      this._keycloakEvents$.next({
        args: authenticated,
        type: KeycloakEventType.OnReady
      });
    };
  }
  loadExcludedUrls(bearerExcludedUrls) {
    const excludedUrls = [];
    for (const item of bearerExcludedUrls) {
      let excludedUrl;
      if (typeof item === 'string') {
        excludedUrl = {
          urlPattern: new RegExp(item, 'i'),
          httpMethods: []
        };
      } else {
        excludedUrl = {
          urlPattern: new RegExp(item.url, 'i'),
          httpMethods: item.httpMethods
        };
      }
      excludedUrls.push(excludedUrl);
    }
    return excludedUrls;
  }
  initServiceValues({
    enableBearerInterceptor = true,
    loadUserProfileAtStartUp = false,
    bearerExcludedUrls = [],
    authorizationHeaderName = 'Authorization',
    bearerPrefix = 'Bearer',
    initOptions,
    updateMinValidity = 20,
    shouldAddToken = () => true,
    shouldUpdateToken = () => true
  }) {
    this._enableBearerInterceptor = enableBearerInterceptor;
    this._loadUserProfileAtStartUp = loadUserProfileAtStartUp;
    this._authorizationHeaderName = authorizationHeaderName;
    this._bearerPrefix = bearerPrefix.trim().concat(' ');
    this._excludedUrls = this.loadExcludedUrls(bearerExcludedUrls);
    this._silentRefresh = initOptions ? initOptions.flow === 'implicit' : false;
    this._updateMinValidity = updateMinValidity;
    this.shouldAddToken = shouldAddToken;
    this.shouldUpdateToken = shouldUpdateToken;
  }
  async init(options = {}) {
    this.initServiceValues(options);
    const {
      config,
      initOptions
    } = options;
    this._instance = new Keycloak(config);
    this.bindsKeycloakEvents();
    const authenticated = await this._instance.init(initOptions);
    if (authenticated && this._loadUserProfileAtStartUp) {
      await this.loadUserProfile();
    }
    return authenticated;
  }
  async login(options = {}) {
    await this._instance.login(options);
    if (this._loadUserProfileAtStartUp) {
      await this.loadUserProfile();
    }
  }
  async logout(redirectUri) {
    const options = {
      redirectUri
    };
    await this._instance.logout(options);
    this._userProfile = undefined;
  }
  async register(options = {
    action: 'register'
  }) {
    await this._instance.register(options);
  }
  isUserInRole(role, resource) {
    let hasRole;
    hasRole = this._instance.hasResourceRole(role, resource);
    if (!hasRole) {
      hasRole = this._instance.hasRealmRole(role);
    }
    return hasRole;
  }
  getUserRoles(realmRoles = true, resource) {
    let roles = [];
    if (this._instance.resourceAccess) {
      Object.keys(this._instance.resourceAccess).forEach(key => {
        if (resource && resource !== key) {
          return;
        }
        const resourceAccess = this._instance.resourceAccess[key];
        const clientRoles = resourceAccess['roles'] || [];
        roles = roles.concat(clientRoles);
      });
    }
    if (realmRoles && this._instance.realmAccess) {
      const realmRoles = this._instance.realmAccess['roles'] || [];
      roles.push(...realmRoles);
    }
    return roles;
  }
  isLoggedIn() {
    if (!this._instance) {
      return false;
    }
    return this._instance.authenticated;
  }
  isTokenExpired(minValidity = 0) {
    return this._instance.isTokenExpired(minValidity);
  }
  async updateToken(minValidity = this._updateMinValidity) {
    if (this._silentRefresh) {
      if (this.isTokenExpired()) {
        throw new Error('Failed to refresh the token, or the session is expired');
      }
      return true;
    }
    if (!this._instance) {
      throw new Error('Keycloak Angular library is not initialized.');
    }
    try {
      return await this._instance.updateToken(minValidity);
    } catch (error) {
      return false;
    }
  }
  async loadUserProfile(forceReload = false) {
    if (this._userProfile && !forceReload) {
      return this._userProfile;
    }
    if (!this._instance.authenticated) {
      throw new Error('The user profile was not loaded as the user is not logged in.');
    }
    return this._userProfile = await this._instance.loadUserProfile();
  }
  async getToken() {
    return this._instance.token;
  }
  getUsername() {
    if (!this._userProfile) {
      throw new Error('User not logged in or user profile was not loaded.');
    }
    return this._userProfile.username;
  }
  clearToken() {
    this._instance.clearToken();
  }
  addTokenToHeader(headers = new HttpHeaders()) {
    return from(this.getToken()).pipe(map(token => token ? headers.set(this._authorizationHeaderName, this._bearerPrefix + token) : headers));
  }
  getKeycloakInstance() {
    return this._instance;
  }
  get excludedUrls() {
    return this._excludedUrls;
  }
  get enableBearerInterceptor() {
    return this._enableBearerInterceptor;
  }
  get keycloakEvents$() {
    return this._keycloakEvents$;
  }
  static {
    this.ɵfac = function KeycloakService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || KeycloakService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: KeycloakService,
      factory: KeycloakService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeycloakService, [{
    type: Injectable
  }], null, null);
})();
class KeycloakBearerInterceptor {
  constructor(keycloak) {
    this.keycloak = keycloak;
  }
  async conditionallyUpdateToken(req) {
    if (this.keycloak.shouldUpdateToken(req)) {
      return await this.keycloak.updateToken();
    }
    return true;
  }
  isUrlExcluded({
    method,
    url
  }, {
    urlPattern,
    httpMethods
  }) {
    const httpTest = httpMethods.length === 0 || httpMethods.join().indexOf(method.toUpperCase()) > -1;
    const urlTest = urlPattern.test(url);
    return httpTest && urlTest;
  }
  intercept(req, next) {
    const {
      enableBearerInterceptor,
      excludedUrls
    } = this.keycloak;
    if (!enableBearerInterceptor) {
      return next.handle(req);
    }
    const shallPass = !this.keycloak.shouldAddToken(req) || excludedUrls.findIndex(item => this.isUrlExcluded(req, item)) > -1;
    if (shallPass) {
      return next.handle(req);
    }
    return combineLatest([from(this.conditionallyUpdateToken(req)), of(this.keycloak.isLoggedIn())]).pipe(mergeMap(([_, isLoggedIn]) => isLoggedIn ? this.handleRequestWithTokenHeader(req, next) : next.handle(req)));
  }
  handleRequestWithTokenHeader(req, next) {
    return this.keycloak.addTokenToHeader(req.headers).pipe(mergeMap(headersWithBearer => {
      const kcReq = req.clone({
        headers: headersWithBearer
      });
      return next.handle(kcReq);
    }));
  }
  static {
    this.ɵfac = function KeycloakBearerInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || KeycloakBearerInterceptor)(i0.ɵɵinject(KeycloakService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: KeycloakBearerInterceptor,
      factory: KeycloakBearerInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeycloakBearerInterceptor, [{
    type: Injectable
  }], () => [{
    type: KeycloakService
  }], null);
})();
class CoreModule {
  static {
    this.ɵfac = function CoreModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [KeycloakService, {
        provide: HTTP_INTERCEPTORS,
        useClass: KeycloakBearerInterceptor,
        multi: true
      }],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [KeycloakService, {
        provide: HTTP_INTERCEPTORS,
        useClass: KeycloakBearerInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();
class KeycloakAngularModule {
  static {
    this.ɵfac = function KeycloakAngularModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || KeycloakAngularModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: KeycloakAngularModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CoreModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeycloakAngularModule, [{
    type: NgModule,
    args: [{
      imports: [CoreModule]
    }]
  }], null, null);
})();
export { CoreModule, KeycloakAngularModule, KeycloakAuthGuard, KeycloakBearerInterceptor, KeycloakEventType, KeycloakService };
