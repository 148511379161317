import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Translation} from "../../../generated/openapi/textservice"
import {TextService} from "../../services/text.service"
import { DesignSystemModule } from '@dvag/design-system-angular'

@Component({
  selector: 'app-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  imports: [
    DesignSystemModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ToastComponent implements OnInit {
  public textObjects: Array<Translation> = []
  @Input() showToast = false
  @Output() showToastChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(
    private textService: TextService
  ) {
    // empty
  }

  ngOnInit(): void {
    this.textObjects = this.textService.getTextsObjectsForIds(162, 163)
  }
}
