import { PipeTransform, Pipe } from "@angular/core"
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"

@Pipe({
  name: "UnsafeUrlPipe",
  standalone: true
})

export class UnsafeUrlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {
    // nothing.
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustUrl(v) // NOSONAR not used in secure contexts
  }
}
